const defaults = {
  APP_NAME: 'chimera',
  CLIENT: 'client',
  DIST: 'dist/chimera',
  LOCAL_STORAGE_NAME: 'lumi_access_token',
  LOCAL_STORAGE_USER_NAME: 'lumi_user',
  LOGIN_PATH: '/hub/login',
  ERROR_LOGGING: true,
  STAFF_PHOTOS_BUCKET: 'https://www.lumi.com.au/staff-images',
  IMPORTANT_DATES_URL:
    'https://00-lumi-customer-hub-assets.s3-accelerate.amazonaws.com/important-dates/dates.json',
  NEWS_ARTICLES_URL: 'https://www.lumi.com.au/blog/wp-json/wp/v2/',
};

const staging = {
  LINK_TIMEOUT_MS: 290000, // 4.8 mins
};

const production = {
  LINK_TIMEOUT_MS: 870000, // 14.5 mins
};

// add any staging / production overrides here
const chimera_config = {
  staging,
  production,
};

/**
 * @param {string} environment
 */
export default function (environment) {
  if (environment !== 'staging' && environment !== 'production') {
    return defaults;
  }

  return {
    ...defaults,
    ...(chimera_config[environment] || {}),
  };
}
