import React from 'react';
import { Field } from 'redux-form';
import invert from 'lodash/invert';
import moment from 'moment';
import lumiEnvironment from '../../lumi.environment';
import tableStyles from '../sass/components/_table.scss';
import { get, isNil, set, isNumber } from 'lodash';

const SALES_ADMIN = 'salesAdmin';
const SALES = 'sales';
const APPROVER = 'approver';
const ADMIN = 'admin';
const BROKER = 'broker';
const COLLECTIONS = 'collections';

export const ROLES = {
  SALES_ADMIN,
  SALES,
  APPROVER,
  ADMIN,
  BROKER,
  COLLECTIONS,
};

export const VALIDATORS = {
  NAME: 'NAME',
  PHONE: 'PHONE',
  EMAIL: 'EMAIL',
  WEBSITE: 'WEBSITE',
  PERCENTAGE: 'PERCENTAGE',
  NUMBERS_ONLY: 'NUMBERS_ONLY',
  BSB: 'BSB',
  ABN: 'ABN',
  ACN: 'ACN',
  LOAN_AMOUNT: 'LOAN_AMOUNT',
  LOAN_TERM: 'LOAN_TERM',
};

export const HOUR_MS = 3600000;

export const MOBILE_BREAKPOINT = 768; // moved from chimera
export const TABLET_BREAKPOINT = 1280;

/**
 * @template TValue
 * @param {TValue} value
 * @returns {TValue || undefined}
 */
export const usePrevious = value => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = React.useRef();

  // Store current value in ref
  React.useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

export const regexTestSimpleEmail = email => {
  return /.+@.+/.test(email);
};

export const getRole = role => {
  const invertedRoles = invert(ROLES);
  return invertedRoles[role];
};

export const mergeClasses = (...classNames) => {
  return classNames.filter(Boolean).join(' ');
};

// Copied from admin/utils
export const getRow = (title, accessor, id, options = {}) => {
  return {
    Header: title,
    accessor,
    className: tableStyles.cellStyle,
    headerClassName: tableStyles.headerStyle,
    id: id,
    ...options,
  };
};

export const title = (
  txt,
  remove_underscores,
  upperCase = true,
  allUpperCase = false,
) => {
  if (!txt) return '';
  const newtxt = txt.replace(new RegExp('_', 'g'), ' ');
  if (allUpperCase) return newtxt.toUpperCase();
  return newtxt.replace(/\w\S*/g, text => {
    if (!upperCase) return text.toLowerCase();
    return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
  });
};

export const formatDate = date => {
  return moment(date).format('DD/MM/YYYY');
};

export const splitName = fullName => {
  const nameParts = fullName.split(' ');
  const first_name = nameParts[0];
  let last_name = '';
  if (nameParts.length > 1) {
    last_name = nameParts.slice(1).join(' ');
  }
  return { first_name, last_name };
};

// Please note that this function operates under the assumption that
// the list you are passingto it is the same attribute you are sending
// to the server.
export const generateSelectOptionsFromList = listOfOptions => {
  const options = listOfOptions.map(option => ({
    label: title(option, true),
    value: option,
  }));
  return options;
};

export const mandatoryFieldsValidation = (values, mandatoryList) => {
  const errors = {};
  mandatoryList.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });
  return errors;
};

export function getUser() {
  const json = localStorage.getItem(lumiEnvironment.LOCAL_STORAGE_USER_NAME);
  if (!json) return {};

  const user = JSON.parse(json);
  return user;
}

export function saveOnboardingState(state) {
  localStorage.setItem(
    `${lumiEnvironment.LOCAL_STORAGE_USER_NAME}_onboarding`,
    JSON.stringify(state),
  );
}

export const getParentPath = path => path.split('/')[2];

export function clearOnboardingState(state) {
  localStorage.removeItem(
    `${lumiEnvironment.LOCAL_STORAGE_USER_NAME}_onboarding`,
  );
}

export function getOnboardingState() {
  const json = localStorage.getItem(
    `${lumiEnvironment.LOCAL_STORAGE_USER_NAME}_onboarding`,
  );
  if (!json) return {};

  const state = JSON.parse(json);
  return state;
}

export const validatePercentage = percentage => {
  const re = /^(?:100|\d{1,2})(?:\.\d{1,2})?$/;
  return re.test(percentage);
};

export const validateCompanyNumber = number => {
  return validateAbn(number) || validateAcn(number);
};

export const validateAbn = abn => {
  if (!abn) return false;
  const _abn = abn.replace(/\ /g, '');
  const re = /^[0-9]{11}$/;
  return re.test(_abn);
};

export const validateAcn = acn => {
  if (!acn) return false;
  const _acn = acn.replace(/\ /g, '');
  const re = /^[0-9]{9}$/;
  return re.test(_acn);
};

export const validateBsb = bsb => {
  const re = /^[0-9]{6}$/;
  return re.test(bsb);
};

export const validateNumbersOnly = numbers => {
  const re = /^-?[0-9]+$/;
  return re.test(numbers);
};

export const validatePositiveNumbersOnly = numbers => {
  if (!numbers) return null;
  if (!validateNumbersOnly(numbers)) return 'Numbers Only';
  if (!numbersGreaterThanZero(numbers)) return 'Must be > 0';
  return null;
};

export const validateNumbersToTwoDecimalsOnly = numbers => {
  const re = /^-?[0-9]+(\.\d{2})?$/;
  return re.test(numbers);
};

export const validateNumbersToOneOrTwoDecimalsOnly = numbers => {
  const re = /^-?[0-9]+(\.\d{1,2})?$/;
  return re.test(numbers);
};

export const validateDecimal = number => {
  const re = /^-?[0-9]+(\.\d*)?$/;
  return re.test(number);
};

export const numbersGreaterThanZero = numbers => {
  const re = /^-?[0-9]+$/;
  return re.test(numbers) && numbers > 0;
};

export const validateName = name => {
  const re = /^[a-z|A-Z|0-9|\s]*$/;
  return re.test(name);
};

export const validateEmail = email => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

export const validateWebsite = website => {
  const re =
    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
  return re.test(website);
};

export const validatePhoneNumber = phone => {
  const re = /^0[0-8]\d{8}$/;
  return re.test(phone);
};

export const validateMobilePhone = phone => {
  let a =
    phone.substring(0, 2) === '04' &&
    phone.trim().replace(/-/g, '').length === 10;
  return a;
};

export const validateMobileToken = token => {
  return /^[0-9]{6}$/.test(token.trim().replace(/-/g, ''));
};

export const validateLoanAmount = (amount, minAmount, maxAmount) => {
  // can be deleted later
  const numbersOnly = validateNumbersToOneOrTwoDecimalsOnly(amount);
  const withinRange =
    isNumber(minAmount) && isNumber(maxAmount)
      ? amount >= minAmount && amount <= maxAmount
      : amount >= 5000 && amount <= 200000;
  return numbersOnly && withinRange;
};

export const validateLoanTerm = (term, minWeeks, maxWeeks) => {
  const numbersOnly = validateNumbersOnly(term);
  const withinRange = term >= minWeeks && term <= maxWeeks;
  return numbersOnly && withinRange;
};

export const formValidator = (validatorType, value) => {
  switch (validatorType) {
    case VALIDATORS.NAME:
      return value && !validateName(value) ? 'Invalid Name' : undefined;
    case VALIDATORS.PHONE:
      return value && !validatePhoneNumber(value) ? 'Invalid Phone' : undefined;
    case VALIDATORS.EMAIL:
      return value && !validateEmail(value) ? 'Invalid Email' : undefined;
    case VALIDATORS.WEBSITE:
      return value && !validateWebsite(value) ? 'Invalid Website' : undefined;
    case VALIDATORS.ABN:
      return value && !validateAbn(value) ? 'Invalid ABN' : undefined;
    case VALIDATORS.ACN:
      return value && !validateAcn(value) ? 'Invalid ACN' : undefined;
    case VALIDATORS.BSB:
      return value && !validateBsb(value) ? 'Invalid BSB' : undefined;
    case VALIDATORS.PERCENTAGE:
      return value && !validateName(value)
        ? 'Invalid Account Number'
        : undefined;
    case VALIDATORS.LOAN_AMOUNT:
      return value && !validateLoanAmount(value)
        ? 'Must be $5,000-$100,000'
        : undefined;
    case VALIDATORS.LOAN_TERM:
      return value && !validateLoanTerm(value)
        ? 'Must be between 13-52'
        : undefined;
    case VALIDATORS.NUMBERS_ONLY:
      return value && !validateNumbersOnly(value) ? 'Numbers Only' : undefined;
    default:
      return 'Invalid Validator Found';
  }
};

export const isInIframe = () => {
  // Detects if the window is currently in an iframe
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const mapField = field => {
  const {
    formName,
    component,
    type,
    options,
    label,
    disabled,
    name,
    onChange,
    validate,
    className,
    wrapperClassName,
    mask,
    keepCharPositions,
    isRequired,
    title,
    placeholder,
    extras,
  } = field;
  let titleComponent;
  if (title) {
    titleComponent = <h5>{title}</h5>;
  }

  return (
    <div key={`${formName}-${name}`} className={wrapperClassName}>
      {titleComponent}
      <Field
        name={name}
        type={type}
        className={className}
        label={label}
        options={options}
        onChange={onChange}
        disabled={disabled}
        component={component}
        validate={validate}
        mask={mask}
        isRequired={isRequired}
        keepCharPositions={keepCharPositions}
        placeholder={placeholder}
        {...extras}
      />
    </div>
  );
};

export const mapFields = fields => {
  return fields.map(field => mapField(field));
};

export const validateRequiredFields = (
  values,
  requiredFields,
  message = 'Required',
) => {
  const errors = {};
  for (const field of requiredFields) {
    const value = get(values, field);
    if (isNil(value) || value === '') set(errors, field, message);
  }
  return errors;
};

export function getUrl(url) {
  const location = window.location;
  return (
    location.protocol +
    '//' +
    location.hostname +
    (location.port ? ':' + location.port : '') +
    url
  );
}

export const removeTrailingSlashes = str => str.replace(/\/+$/, '');

export function base64ToBlob(base64String, contentType = 'application/pdf') {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: contentType });
}
