import gql from 'graphql-tag';
import lumiEnvironment from '../../lumi.environment';
import {
  apiPost,
  publicServerURL,
  rawApiFetch,
  serverURL,
} from '../utils/fetch_utils';
import {
  GraphQLResponse,
  usePublicGraphMutation,
} from '../utils/graphql_utils';
import { BankingReportStatus } from '../api/dataTypes/bankingReport';

export const authenticateAction = async (args: {
  email: string;
  password: string;
  role: string;
  isPublicApi?: boolean;
}) => {
  const { email, password, role, isPublicApi = true } = args;
  const result = await rawApiFetch(
    `${isPublicApi ? publicServerURL : serverURL}/api/accounts/authenticate`,
    {
      method: 'post',
      body: { email, password, role },
    },
  );
  if (!result.success) throw new Error(result.error);
  return result;
};

type LogoutResponse = GraphQLResponse<{
  logout: {
    success: boolean;
  };
}>;

export const useLogoutMutation = (
  onSuccess: () => void,
  onError?: () => void,
) =>
  usePublicGraphMutation<LogoutResponse>(
    gql`
      mutation Logout {
        logout {
          success
        }
      }
    `,
    {
      throwOnError: true,
      onSuccess,
      onError,
    },
  );

type BanklinkArgs = {
  referenceId: string;
  redirectUrl: string;
  type: string;
  requestExportSelection: boolean;
};

export const getLinkGqlQuery = gql`
  mutation (
    $requestExportSelection: Boolean
    $redirectUrl: String
    $type: String!
    $referenceId: String!
  ) {
    newLink(
      request_export_selection: $requestExportSelection
      redirect_url: $redirectUrl
      type: $type
      reference: $referenceId
    ) {
      url
      reference
    }
  }
`;

export const markBankingReportAwaitingDataDocumentNode = gql`
  mutation MarkBankingReportAwaitingData($token: String!) {
    markBankingReportAwaitingData(token: $token) {
      success
    }
  }
`;

export const markBankingReportFailedDocumentNode = gql`
  mutation MarkBankingReportFailed(
    $token: String!
    $status: String!
    $errorCode: Int!
  ) {
    markBankingReportFailed(
      token: $token
      status: $status
      errorCode: $errorCode
    ) {
      success
    }
  }
`;
